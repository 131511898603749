<template>
  <div class="aboutDetail">
    <div class="banner">
      <div class="banner-title">
        <h2>招聘</h2>
        <p>您当前的位置：
          <span style="cursor: pointer;" @click="$router.push('/index')">首页</span>
          <span>></span>
          <span style="cursor: pointer;" @click="goAbout">招聘</span>
        </p>
      </div>
    </div>
    <div class="content" v-html="content.jobContent">

    </div>
  </div>
</template>
<script>
import {jobOffersDetail} from '@/api/about/about.js'
export default {
  data() {
    return {
      content: {}
    }
  },
  mounted() {
    jobOffersDetail(this.$route.query.id).then(res => {
      this.content = res.data.data
      console.log(this.content)
    })
  },
  methods: {
    goAbout() {
      this.$router.push({
        path: '/index',
        query: {invite: true}
      })
    }
  }
}
</script>
<style lang="less" scoped>
.aboutDetail{
   min-height: 800px;
   background-image: url('../../assets/bg.png');
   background-repeat: no-repeat;
   background-size: 100% 100%;
   .banner{
     height: 182px;
     background: url('../../assets/detailBg.png') no-repeat;
     background-size: 100%;
     .banner-title{
        width: 1200px;
        margin: 0 auto;
        // border: 1px solid #fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2{
          color: white;
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: normal;
        }
        p{
          color: #e9e9eb;
          font-size: 12px;
        }
     }
   }
   .content{
    padding-top: 40px;
     width: 1200px;
     margin: 0 auto;
   }
}
</style>